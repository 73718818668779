/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

        // Add class to header for sticky
        $(window).scroll(function() {    
            var scroll = $(window).scrollTop();
            var objectSelect = $("header#site-header");
            var objectPosition = objectSelect.offset().top;
            if (scroll > objectPosition) {
                $("header#site-header .navbar-default").addClass("mini");
            } else {
                $("header#site-header .navbar-default").removeClass("mini");
            }
        });

        function headerResponsiveTasks() {
          // Append header right side links to mobile menu
          $('header#site-header ul.navbar-right').appendTo('.navbar-header');
          // DOESN'T WORK
          // Mobile menu toggle: change from bars to X
          /*var menu_bars = function ( that ) {
            if($(that).hasClass('fa-bars')) {
              $(that).removeClass("fa-bars").addClass("fa-times");
            } else {
              $(that).addClass("fa-bars").removeClass("fa-times");
            }
          };
          $('.navbar-toggle i').bind('touchstart', function(){
            menu_bars(this);
          });*/
        }
        
        function pricingPagePlanFeatures() {
          // Pricing Pages, remove "collapse" class
          $('.plan-features ul.collapse').removeClass('collapse');
        }

        // Smooth Scroll
        $("a[href^='#']:not(.back-to-top)").on('click', function(e) {
           // prevent default anchor click behavior
           e.preventDefault();
           // store hash
           var hash = this.hash;
           // animate
					 if ($(hash).offset()) {
					 	$('html, body').animate({
					 		scrollTop: $(hash).offset().top
					 	}, 800, function(){
					 		// when done, add hash to url
					 		// (default click behaviour)
					 		window.location.hash = hash;
					 	});
					 }
        });
        
        // Back to Top
				$(window).scroll(function () {
						if ($(this).scrollTop() > 50) {
								$('#back-to-top').fadeIn();
						} else {
								$('#back-to-top').fadeOut();
						}
					});
					// scroll body to 0px on click
					$('#back-to-top').click(function () {
						//$('#back-to-top').tooltip('hide');
						$('body,html').animate({
								scrollTop: 0
						}, 800);
						return false;
					});
					//$('#back-to-top').tooltip('show');
        
        function checkForViewportChange () {
          var state = window.getComputedStyle(document.body,':before').content;
          this.lastState = this.lastState || "";
          if (state !== this.lastState) {
          if ((state === '\"mobile\"') || (state === 'mobile')) {
            // Header: run headerResponsiveTasks
            headerResponsiveTasks();
            // Testimonial: add text-center & update image
            $('section#testimonial').addClass('text-center');
            $('section#testimonial img.img-responsive').removeClass('pull-left').addClass('center-block');
            // Services: add text-center
            $('section#services').addClass('text-center');
            // Team: add text-center to some paragraphs & center-block to photos
            $('section#team h1,section#team .team.all p').addClass('text-center');
            $('section#team .team .photo').removeClass('pull-left').removeClass('pull-right');
            $('section#team .team .photo img').addClass('center-block');
          } else if ((state === '\"tablet\"') || (state === 'tablet')) {
            // Header: run headerResponsiveTasks
            headerResponsiveTasks();
          } else if ((state === '\"desktop\"') || (state === 'desktop')) {
            // Let's Talk Modal Form: increase size with "modal-lg" class
            $('#contact.modal .modal-dialog').addClass('modal-lg');
            // Content Blocks: add "text-right" to specific blocks
            $('#content-blocks .col-md-5.col-md-pull-5.description').addClass('text-right');
            // Pricing Pages: remove "collapse"
            pricingPagePlanFeatures();
          }
          this.lastState = state;
        }}
        window.setInterval (checkForViewportChange, 150);



      },
      finalize: function() {
				$('.plan-cta a').click(function () {
					$('.seo-plan').val($(this).data('plan'));
				});
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Single Portfolio Pages
    'page_template_template_portfolio_single': {
      init: function() {
        // Single Portfolio Pages - make divs match height then apply images as background images
        // >> apply source
        (function($) {
        $('body.page-template-template-portfolio_single .icon.switch-to-bg-image').each(function(){
          // Copy img href to background url href and apply 500px height; height won't matter for the text/icon blocks since jquery.matchheight will follow and change it - this is mostly needed for the full width background image
          $(this).css('background-image', 'url(' + $('body.page-template-template-portfolio_single .icon.switch-to-bg-image img').attr('src') + ')').css('height', '1100');
          // Remove img tag
          $(this).children().remove();
        });
        // >> match text/icon divs
        $('body.page-template-template-portfolio_single .row .matchheight').matchHeight();
        })(jQuery);
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
